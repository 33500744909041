///
/// @file global/_variables.scss
///
/// \brief Variables
///
/// These are universally needed files that are SCSS-specific. Most of the
/// following do not directly result in rendered CSS.
/// @setup change all for new brand

// Layout
$max-width: 1440px !default;
$mobile-width-limit: 767px !default;
$page-max-width: 2560px !default; // this is the widest we will allow for
// content section at full bleed
$admin-toolbar-height: 101px !default;

// Max widths for formatters and content containers
$container-max-width-landscape: 1440px !default;

// Max widths for slide panel
$slide-panel-max-width: 385px;

// Content containers outer pad vars
$container-pad-small: 20px !default;
$container-pad-landscape: 44px !default;

// Accordion padding
$accordion-padding-desktop: 46px;
/* Primary grey color types */
// #todo recommend removing these here and from styleguide so brand specific colors remain
$color-primary-900: #212121;
$color-primary-800: #424242;
$color-primary-700: #616161;
$color-primary-600: #707070;
$color-primary-500: #9e9e9e;
$color-primary-400: #bdbdbd;
$color-primary-300: #e0e0e0;
$color-primary-200: #eeeeee;
$color-primary-100: #f5f5f5;
$color-para-grey: #5f5f5f;
$color-primary-50: #fafafa;
$color-grey-transparent: rgba(0, 0, 0, 0.5);
$color-grey-transparent-10: rgba(0, 0, 0, 0.1);
$color-grey-transparent-70: rgba(0, 0, 0, 0.7);
$color-grey-transparent-75: rgba(0, 0, 0, 0.75);
$color-grey-transparent-50: rgba(0, 0, 0, 0.5);
$color-white-transparent: rgba(255, 255, 255, 0.8);
$color-white-transparent-50: rgba(255, 255, 255, 0.5);
$color-gray-light-alt: #eff0f0;
$color-grey-86: rgba(0, 0, 0, 0.86);
$color-grey-disabled: #757575;
$color-sharp-black: #111111;
$color-shimmer-grey: #edeef1;
$color-shimmer-grey-light: #f6f7f8;

// Colors (http://sachagreif.com/sass-color-variables/)
$black: #1c1c1c !default;
$red: #ff0000 !default;
$white: #ffffff !default;
$color-black: $black !default;
$color-white: $white !default;
$color-gray: $color-primary-600 !default;
$color-grey: $color-gray !default;
$color-light-gray: $color-primary-100 !default;
$color-light-grey: $color-light-gray !default;
$color-dark-gray: $color-primary-900 !default;
$color-dark-grey: $color-dark-gray !default;
$color-lighter-gray: $color-primary-400 !default;
$color-lighter-grey: $color-lighter-gray !default;
$color-blue-dark: #4a76a8;
$color-blue-darker: #3c5a99;
$color-blue-darkest: #313976;
$color-cream: #fcf9ee;
$color-cream-lighter: #fbf9f4;
$color-cream-stroke: #e2e1d7;
$color-cream-disabled: #e1e2d7;
$color-cream-light: #e6e6e6;
$color-cream-stroke-transparent-30: rgba(#e1e2d7, 0.3);
$color-cream-dark: #f7f4e9;
$color-focus-default: #4d90fe;
$color-red-accessibility-highlight: #e66475;
$color-red-error: #d14646;
$color-black-dark: #000000;
$color-charleston-green: #292929;
$color-bianca: #f7f4e9;
$color-cyan-transparent-20: rgba(108, 117, 125, 0.2);
$color-alabaster: #f2efe5;
$pastel-gray: #c6c5be;

// Brand Colors
$color-grey-light: #ccc9c1 !default;
$color-grey-dark: #999999 !default;
$color-pink: #ff4661 !default;
$color-green: #005c3e !default;
// @todo audit grey colors to match what we need for brand
$color-gnav-grey: #707070;
$color-bg-slide-grey-transparent: $color-grey-transparent-75;

$color-text: $black !default;
$color-link: $black !default;
$color-link-visited: $black !default;
$color-link-hover: $black !default;

// enterprise colors
$color-grey-dark-liver: #4f4f4f;

// frontend validation variables
$color-valid: #0cc42f;
$color-success: $color-valid;
$color-error: #cc0000;
$color-invalid: $color-error;
$color-warning: #ebbb00;
$color-notification: #fc7000;
$color-focus: #54a6d7;
$border-color-normal: $color-lighter-gray;
$border-color-error: $color-error;
$icon-background: $white;
$inline-error-background: $white;
$color-light-blue: #22558e;
$color-lighter-blue: #94abc0;
$lux-error: #c40000;
$lux-background-error: #fdf4ea;
$color-notice-pink: #ef6ea8;

// Product cards style
$color-darker-grey: #222;
$color-darkest-grey: #3a3a3a;
$color-dusty-grey: #9c9c9c;
$color-silver-grey: #cacaca;
$color-border-grey: #e6e6e6;

// Directions
$rdirection: right !default;
$ldirection: left !default;
$text-direction: ltr !default;
$is-rtl: false !default;

// Fonts / Typography related variables
// Fonts Family
$font--serif: serif !default;
$font--sans: sans-serif !default;
$font--mono: monospace !default;
$font--malone: 'Malone Serif', times, 'Times New Roman', serif !default;
$font--lars: 'Lars Malone', helvetica, arial, sans-serif !default;
$font--lars-bold: $font--lars;
$font--bernhard-bold: 'Bernhard Modern Bold', impact, 'Marker Felt', fantasy !default;
$font--kuenstler: 'Kuenstler Script', 'Apple Chancery', 'Bradley Hand', cursive !default;

// Font Sizes
$base-fontsize: 13px !default; // changed var from $base-font-size because
// compass is overriding
$base-line-height: 1.6 !default;
$base-letter-spacing: 0.01em !default;

// Gnav specific vars
$gnav-fixed-z-index: 1000 !default;
$gnav-pc-hpad: 56px !default; // @todo finalize this
$navbar-height: 70px !default;
$navbar-offset: 20px !default;
$menu-height: $navbar-height * 2;
$offerbar-height: $navbar-height / 2;
$offerbar-mobile-height: 50px;
$offerbar-opt-height: 50px;
$sitewide-banner-height: 90px;
$sitewide-banner-mobile-height: 135px;
$gnav-height: $menu-height + $offerbar-height;
$gnav-mobile-height: $navbar-height + $offerbar-mobile-height;
$gnav-icon: 22px !default;
$gnav-icon--sm: 14px !default;
$gnav-mobile-hpad--outer: 22px !default;
$gnav-mobile-hpad--inner: 16px !default; // 38 (pad) - 22 (outer pad)
$gnav-mobile-vpad--sm: 28px !default;
$gnav-mobile-vpad--med: 56px !default; // or can use small above and below
$gnav-mobile-vpad--lg: 64px !default;
$header-height: $offerbar-mobile-height + $sitewide-banner-height; // used to offset anchors, etc.

$border-line--input: 1px solid $color-gray;
$border-line--input-light-gray: 1px solid $color-gray;

// Text translations vars
$reviews_helpful_yes: 'Yes' !default;
$reviews_helpful_no: 'No' !default;

// JP specific vars
$sub-line-as-title: false !default;
$is-tax-include: false !default;
$is-lengthy-engraving-cta: false !default;
$enable-global-font-for-oab-intro-title: false !default;
$is-lengthy-mpp-cta: false !default;
$sd_disabled_toos_cta: false !default;
$sd_hide_toos_cta: false !default;
$is-engraving-has-choice-8: false !default;

// SHARED Design enable
$apac_checkout_shared_designs: true !default;

// ELC BASE - SHARED Design enable
$elc_base_shared_checkout_designs: true !default;

// shared v3 variables
$color-classic-matte-black: #1c1c1c !default;
$color-dark-red: #cc0000 !default;
$color-dark-black: #000000;
$color-forest-green: #06891e;
$color-green-darker: #087443;
$color-green-v2: #a3d6c7 !default;
$color-navy-blue: #0075db;

$main-font: $font--lars;
$main-font-regular: $font--malone;
$base-font-family: $font--lars;
$body-font: $font--lars;
$body-font-bold: $font--lars;
$bold-font-family: $font--lars;
$header-font-family: $font--lars;
$medium-font-family: $font--lars;
$light-font-family: $font--lars;
$main-font-medium: $medium-font-family;
$main-font-light: $light-font-family;

$medium-up: 768px !default;
$jom_customise_gift_design: false !default;
//My appointment variables
$background-color_strawberry: #ff4661;
$background-color_gray: #9d9d9d;
$background-color_gray_transparent: rgba(255, 255, 255, 0.7);
$background-color_transparent: transparent;
$border-color_dirty_white: #eff0f0;
$border-color_light_gray: #d4d4d4;
$border-color_gray: #9d9d9d;
$border-color_transparent: transparent;
$mobile: 320px;
$mobile-landscape: 480px;
$tablet-landscape: 1024px;

$elc_order_tracking_margin_value: 0 !default;
